<template>
  <div class="contact-information">
    <Phone
      v-model="form.phone"
      :error="formErrors.phone"
      :disabled="!!(contactData || {}).id"
      title="Телефон"
    />
    <Field
      v-model="form.email"
      :error="formErrors.email"
      title="e-mail"
    />
  </div>
</template>

<script>
import "./contact-information.scss";
import Phone from "@index/components/common/field/phone";
import Field from "@index/components/common/field/field";
import validation from "@index/ext/mixins/validation";
import Validation from "@index/ext/validation/validation";

export default {
  name: "ContactInformation",
  components: {
    Phone,
    Field,
  },
  mixins: [validation],
  props: {
    contactData: {
      type: Object || undefined,
      default: () => ({
        phone: "",
        email: "",
      }),
    },
  },
  data() {
    return {
      form: {
        phone: "",
        email: "",
        id: null,
      },
      formRules: {
        phone: [Validation.REQUIRED, Validation.PHONE],
        email: [Validation.EMAIL],
      },
    };
  },
  created() {
    const { phone = "", email = "", id } = this.contactData || {};

    this.form.phone = phone;
    this.form.email = email;

    if (this.form.phone || id) return;

    this.form.phone = this.$route.query?.phone || "";
  },
};
</script>
