<template>
  <label
    class="checkbox"
    :class="classes"
  >
    <span class="checkbox__element">
      <input
        v-model="valueMy"
        type="checkbox"
        :value="value"
      >
      <span />
    </span>
    <span class="checkbox__label">
      <slot />
    </span>
  </label>
</template>

<script>
export default {
  name: "Checkbox",
  model: {
    prop: "model",
    event: "change",
  },
  props: {
    isRound: {
      type: Boolean,
      default: false,
    },
    model: {
      type: [Boolean, Array],
    },
    error: {
      type: Boolean,
      default: false,
    },
    value: {
      type: [String, Number, undefined],
      default: undefined,
    },
  },
  computed: {
    valueMy: {
      get() {
        return this.model;
      },
      set(v) {
        this.$emit("change", v);
      },
    },
    classes() {
      return {
        checkbox_error: this.error,
      };
    },
  },
};
</script>

<style lang="scss">
@import "checkbox";
</style>
