<template>
  <div class="card">
    <div class="card__front">
      <div class="card__first-line">
        <Label
          class="mb-3 mt-2 w-100"
          :validation-message="formErrors.number"
          is-validation-grow
        >
          <template #input> Номер карты </template>
          <Input
            v-model="form.number"
            :validation-message="formErrors.number"
            class="card-recurrent__input-card"
            name="cc-number"
            type="text"
            placeholder=" "
            inputmode="numeric"
            mask="#### #### #### #### ###"
            autofocus
            autocomplete="cc-number"
          />
        </Label>
      </div>
      <div class="card__second-line">
        <Field
          v-model="form.holder_name"
          :error="formErrors.holder_name"
          class="card__owner"
          title="Владелец"
          dont-show-error-msg
        />
        <Field
          v-model="form.date"
          :error="formErrors.date"
          mask="##/##"
          class="card__date"
          title="Срок"
          dont-show-error-msg
        />
      </div>
    </div>
    <div class="card__back">
      <Field
        v-model="form.cvv"
        :error="formErrors.cvv"
        mask="###"
        class="card__cvv"
        title="CVV"
        type="password"
        dont-show-error-msg
      />
    </div>
    <div
      v-show="errors.length"
      class="card__errors"
    >
      <p
        v-for="error in errors"
        :key="error"
      >
        {{ error }}
      </p>
    </div>
  </div>
</template>

<script>
import validation from "@index/ext/mixins/validation";
import Validation from "@index/ext/validation/validation";
import {
  MIN as MIN_VAL,
  MAX as MAX_VAL,
  CARD_OWNER_EXP,
} from "@index/ext/validation/validationValues";
import {
  year as currentYear,
  month as currentMonth,
} from "@index/ext/helpers/date/currentDate";

const ALLOW_CARD_YEARS = 15;

const { REQUIRED, MIN, MAX, EXP, DATE, DATE_MORE, DATE_LESS, CARD } =
	Validation;

const TODAY = `01.${currentMonth}.${currentYear}`;
const MAX_CARD_DATE = `01.${currentMonth}.${currentYear + ALLOW_CARD_YEARS}`;

const FORM = {
  number: "",
  holder_name: "",
  date: "",
  cvv: "",
};

export default {
  name: "Card",
  components: {
    Field: () => import("@index/components/common/field/field"),
  },
  mixins: [validation],
  props: {
    cardData: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      form: FORM,
      formRules: {
        number: [REQUIRED, [MIN, 13], CARD],
        date: [
          REQUIRED,
          DATE,
          [DATE_MORE, TODAY],
          [DATE_LESS, MAX_CARD_DATE],
        ],
        holder_name: [
          REQUIRED,
          [MIN, MIN_VAL],
          [MAX, MAX_VAL],
          [EXP, CARD_OWNER_EXP],
        ],
        cvv: [REQUIRED, [MIN, 3], [MAX, 3]],
      },
      customErrors: {
        number: {
          [MIN]: "Номер карты содержит не менее {{0}} цифр",
          [MAX]: "Номер карты содержит не более {{0}} цифр",
        },
        holder_name: {
          [EXP]: "Только символы латинского алфавита",
        },
        date: {
          [DATE]: "Срок указан не полностью",
          [DATE_MORE]: "Карта просрочена",
          [DATE_LESS]: `Мы принимаем карты сроком до ${ALLOW_CARD_YEARS} лет`,
        },
      },
      error: "",
    };
  },
  computed: {
    errors() {
      const { number, holder_name, date, cvv } = this.formErrors;

      return [this.error, number, holder_name, date, cvv].reduce(
        (acc, item) => {
          if (item && !acc.includes(item)) {
            acc.push(item);
          }

          return acc;
        },
        []
      );
    },
  },
  watch: {
    request(v) {
      this.error = "";
      this.$emit("request", v);
    },
    "form.date"(date) {
      if (!(date || "").length) return;

      const [month] = date.split("/");

      if (month > 12 || (month.length === 2 && +month === 0)) {
        this.form.date = "12";
      }
    },
  },
  created() {
    this.initData();
  },
  methods: {
    initData() {
      const card = (this.cardData || {}).card || {};
      
      this.form.number = card.cardNumber;
      this.form.holder_name = card.cardHolder;
      this.form.date = card.cardExpiry;
      this.form.cvv = card.cardCvv;
    },
    validationData(data) {
      console.log("validationData data : ", data)

      let { date = "", number = "" } = data;

      if (date.length) {
        let [month, year = ""] = date.split("/");

        let yearStart = +String(currentYear).substr(0, 2);
        const yearEnd = +String(currentYear).substr(2);
        if (yearEnd < yearStart && yearEnd - ALLOW_CARD_YEARS <= 0)
          ++yearStart;

        year = `${yearStart}${year}`;
        date = `01.${month}.${year}`;
      }

      return {
        ...this.form,
        number: number.replace(/ /g, ""),
        date,
      };
    }
  },
};
</script>

<style lang="scss">
@import "card";
</style>
