<template>
  <div
    class="code"
    :class="{
      code_wait: timer,
    }"
  >
    <Field
      v-model="form.code"
      title="Код из СМС"
      :error="formErrors.code"
      autocomplete="one-time-code"
      mask="#####"
      class="code__field"
    />
    <span
      v-if="isDisabledUpdate"
      class="code__repeat"
    >
      Через {{ formatTime }} сек. будет доступно повторное СМС
    </span>
    <span
      v-else
      class="code__repeat"
      @click="getCode"
    >
      Выслать СМС повторно
    </span>
  </div>
</template>

<script>
import "./code.scss";
import validation from "@index/ext/mixins/validation";
import Field from "@index/components/common/field/field";

export default {
  name: "Code",
  components: {
    Field,
  },
  mixins: [validation],
  props: {
    getCode: {
      type: Function,
      default: () => {},
    },
    timeoutSecondsMs: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      form: {
        code: "",
      },
      formRules: {
        code: [validation.REQUIRED, [validation.MIN, 5]],
      },
      timer: 0,
    };
  },
  computed: {
    formatTime() {
      return new Date(this.timeoutSecondsMs)
        .toLocaleString("ru-RU")
        .slice(-5)
        .replace("00:", "");
    },
    isDisabledUpdate() {
      return this.timeoutSecondsMs > 0;
    },
  },
  watch: {
    "form.code"(v) {
      v.length === 5 && this.$emit("ready");
    },
  },
};
</script>
