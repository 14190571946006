<template>
  <div class="personal-information">
    <div class="personal-information__line">
      <Field
        v-model="form.lastname"
        :error="formErrors.lastname"
        title="Фамилия"
      />
      <Field
        v-model="form.firstname"
        :error="formErrors.firstname"
        title="Имя"
      />
    </div>
    <div class="personal-information__line">
      <Field
        v-model="form.patronymic"
        :error="formErrors.patronymic"
        title="Отчество"
        subtitle="(если есть)"
      />

      <Date
        v-model="form.birthday"
        :error="formErrors.birthday"
        title="Дата рождения"
      />
    </div>
  </div>
</template>

<script>
import "./personal-information.scss";

import Field from "@index/components/common/field/field";
import Date from "@index/components/common/field/date";

import validation from "@index/ext/mixins/validation";
import Validation from "@index/ext/validation/validation";

import {
  MIN as MIN_VAL,
  MAX as MAX_VAL,
  NAME_EXP,
} from "@index/ext/validation/validationValues";
const NAME_RULES = [
  Validation.NO_FILTHY,
  [Validation.EXP, NAME_EXP],
  [Validation.MIN, MIN_VAL],
  [Validation.MAX, MAX_VAL],
];
const NAME_ERROR_MSG = {
  [Validation.EXP]: "Допустимы только русские буквы, дефис и апостроф",
};

const MAX_BORROWER_OLD = 75;
const MIN_BORROWER_OLD = 18;

import { year, month, day } from "@index/ext/helpers/date/currentDate";
const MAX_BORROWER_YEAR = `${day}.${month}.${year - MAX_BORROWER_OLD}`;
const MIN_BORROWER_YEAR = `${day}.${month}.${year - MIN_BORROWER_OLD}`;

export default {
  name: "PersonalInformation",
  components: {
    Field,
    Date,
  },
  mixins: [validation],
  props: {
    contactData: {
      type: Object || undefined,
      default: () => ({
        firstname: "",
        lastname: "",
        patronymic: "",
        birthday: "",
      }),
    },
  },
  data() {
    return {
      form: {
        firstname: "",
        lastname: "",
        patronymic: "",
        birthday: "",
      },
      formRules: {
        firstname: [Validation.REQUIRED, ...NAME_RULES],
        lastname: [Validation.REQUIRED, ...NAME_RULES],
        patronymic: NAME_RULES,
        birthday: [
          Validation.REQUIRED,
          Validation.DATE,
          [Validation.DATE_LESS, MIN_BORROWER_YEAR],
          [Validation.DATE_MORE, MAX_BORROWER_YEAR],
        ],
      },
      customErrors: {
        firstname: NAME_ERROR_MSG,
        lastname: NAME_ERROR_MSG,
        patronymic: NAME_ERROR_MSG,
        birthday: {
          [Validation.DATE_LESS]: `Вам должно быть ${MIN_BORROWER_OLD} лет`,
          [Validation.DATE_MORE]: `Возраст не должен превышать ${MAX_BORROWER_OLD} лет`,
        },
      },
    };
  },
  watch: {
    contactData: [
      {
        handler: "onContactDataChanged",
        immediate: true,
        deep: true,
      },
    ],
    "form.birthday"(v) {
      this.$emit("changeBirthday", v);
    },
  },
  methods: {
    onContactDataChanged() {
      const account = this.contactData.account || {};

      this.form.firstname = account.firstname;
      this.form.lastname = account.lastname;
      this.form.patronymic = account.patronymic || "";
      this.form.birthday = account.birthday;
    },
  },
};
</script>
