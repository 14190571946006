<template>
  <div>
    <label>
      <input
        v-model="isProject"
        type="radio"
        value="beriberu"
      >
      beriberu
    </label>
    <label>
      <input
        v-model="isProject"
        type="radio"
        value="davaka"
      >
      davaka
    </label>
    <label>
      <input
        v-model="isProject"
        type="radio"
        value="hurma"
      >
      hurma
    </label>
    <label>
      <input
        v-model="isProject"
        type="radio"
        value="service"
      >
      krediska
    </label>
    <Button @click="onIdentity">
      identity
    </Button>
  </div>
</template>

<script>
export default {
  name: "Identity",
  data:()=>({
    isProject: false
  }),
  mounted() {
    if (process.env.NODE_ENV !== "development") {
      this.$router.push({ name: "landing" });
    }
  },
  methods: {
    onIdentity() {
      const salt = Math.random().toString().split(".")[1].slice(0, 9);

      this.$infra.finvin
        .init("krediska")
        .then((res) =>
          this.$infra.finvin.step({
            token: res.token,
            step: res.step,
            phone: "+79" + salt,
            email: "ya" + salt + "@ya.ru",
            firstname: "Жан-Клод",
            lastname: "Ван Дамм",
            patronymic: null,
            passport_serie: "1800",
            passport_number: "350000",
            passport_issuecode: "340-005",
            passport_issuedate: "11.11.2011",
            passport_issuer: "УФМС России",
            birthday: "30.04.1989",
            address: "улица Пушкина, дом Колотушкина",
            timeZone: "Europe/Moscow",
            meta: [],
          })
        )
        .then((res) => {
          if(this.isProject){
            window.location.href =
							window.location.origin +
							"/"+ this.isProject +"?identity=" +
							res.token;
            return;
          }

          window.location.href =
							window.location.origin +
							"/service?identity=" +
							res.token
        })
        .catch((e) => {
          alert("catcha", e);
        });
    },
  },
};
</script>
