<template>
  <div class="passport-information">
    <div class="passport-information__line">
      <Address
        v-model="form.contactData.addrcity"
        title="Адрес регистрации"
        :error="formErrors.contactData.addrcity"
      />
    </div>
    <div class="passport-information__combined">
      <Field
        v-model="form.passportData.passportseries"
        :error="formErrors.passportData.passportseries"
        title="Серия"
        mask="## ##"
        auto-tab="number"
        value-without-mask
      />
      <Field
        v-model="form.passportData.passportnumber"
        :error="formErrors.passportData.passportnumber"
        title="Номер"
        name="number"
        auto-tab="issuecode"
        mask="### ###"
        value-without-mask
      />
      <Field
        v-model="form.passportData.passportissuecode"
        :error="formErrors.passportData.passportissuecode"
        title="Код подразделения"
        name="issuecode"
        auto-tab="issuer"
        mask="###-###"
        value-without-mask
      />
    </div>
  </div>
</template>

<script>
import "./passport-information.scss";
import Field from "@index/components/common/field/field";
import Address from "./address";

import validation from "@index/ext/mixins/validation";
import Validation from "@index/ext/validation/validation";

import { year, month, day } from "@index/ext/helpers/date/currentDate";
const TODAY = `${day}.${month}.${year}`;

const ISSUE_DATE_VALIDATE = [Validation.DATE, [Validation.DATE_LESS, TODAY]];

export default {
  name: "PassportInformation",
  components: {
    Field,
    Address,
  },
  mixins: [validation],
  props: {
    contactData: {
      type: Object || undefined,
      default: () => ({}),
    },
  },
  data() {
    return {
      form: {
        contactData: {
          addrcity: "",
          birthday: "",
        },
        passportData: {
          passportissuecode: "",
          passportnumber: "",
          passportseries: "",
        },
      },
      formRules: {
        contactData: {
          addrcity: [Validation.REQUIRED],
        },
        passportData: {
          passportissuecode: [
            Validation.REQUIRED,
            [Validation.MIN, 6],
            [Validation.MAX, 6],
          ],
          passportnumber: [
            Validation.REQUIRED,
            [Validation.MIN, 6],
            [Validation.MAX, 6],
          ],
          passportseries: [
            Validation.REQUIRED,
            [Validation.MIN, 4],
            [Validation.MAX, 4],
          ],
        },
      },
    };
  },
  watch: {
    contactData() {
      this.initData();
    },
    "form.contactData.birthday"(v) {
      if (!v) return;

      const passportRules = this.formRules.passportData;
      const [birthDay, birthMonth, birthYear] = v
        .split(".")
        .map((i) => +i);

      let age = year - birthYear;
      if (birthMonth > month || (birthMonth === month && birthDay > day))
        age--;

      if (!birthYear || birthYear.length < 4)
        passportRules.passportissuedate = ISSUE_DATE_VALIDATE;
      else if (age >= 45)
        passportRules.passportissuedate = [
          ...ISSUE_DATE_VALIDATE,
          [
            Validation.DATE_MORE,
            `${birthDay}.${birthMonth}.${birthYear + 45}`,
          ],
        ];
      else if (age >= 20)
        passportRules.passportissuedate = [
          ...ISSUE_DATE_VALIDATE,
          [
            Validation.DATE_MORE,
            `${birthDay}.${birthMonth}.${birthYear + 20}`,
          ],
        ];
      else if (age >= 18)
        passportRules.passportissuedate = [
          ...ISSUE_DATE_VALIDATE,
          [
            Validation.DATE_MORE,
            `${birthDay}.${birthMonth}.${birthYear + 14}`,
          ],
        ];

      this.formIsSubmit && this.validate(false);
    },
  },
  created() {
    this.initData();
  },
  methods: {
    initData() {
      const account = this.contactData.account || {};

      this.form.contactData.addrcity = account.address;
      this.form.passportData.passportissuecode = account.passport_issuecode;
      this.form.passportData.passportnumber = account.passport_number;
      this.form.passportData.passportseries = account.passport_serie;
      this.form.birthday = account.birthday;
    },
  },
};
</script>
