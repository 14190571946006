<template>
  <div class="iframe3ds">
    <Button
      class="__btn"
      type="clear"
      @click="onClose"
    >
      <svg
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z"
          fill="black"
        />
      </svg>
    </Button>
  </div>
</template>

<style>
.iframe3ds {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  height: calc(100vh - 100px);
  position: relative;
}

.iframe3ds .__btn {
  position: absolute;
  right: 0;
  padding: 20px;
}
</style>

<script>
export default {
  name: "Iframe3ds",
  props: {
    initIframeFun: {
      type: Function,
      default: () => {},
    },
  },
  mounted() {
    this.initIframeFun(this.$el);
  },
  methods: {
    onClose() {
      this.$emit("close");
    },
  },
};
</script>
