<template>
  <Select
    ref="select"
    v-model="addressModel"
    items-key="value"
    :before-list="warning || beforeList"
    label="value"
    :show-loading="showLoading"
    :title="title"
    :error="error"
    :list="list"
    @focus="focus"
    @input="getListTimeout"
    @listClick="clearList"
  />
</template>

<script>
import Select from "./select/select";

export default {
  name: "AddressSearch",
  components: {
    Select,
  },
  model: {
    prop: "model",
    event: "change",
  },
  props: {
    title: {
      type: String,
      default: "Адрес",
    },
    model: {
      type: String,
    },
    error: {
      type: String,
      default: "",
    },
    to: {
      type: [String, Boolean],
      default: true,
    },
  },
  data() {
    return {
      list: [],
      warning: "",
      timeoutID: null,
      showLoading: 0,
      isMounted: false,
    };
  },
  computed: {
    beforeList() {
      if (!this.isMounted) return;

      if (!this.addressModel) {
        if (this.$refs.select && this.$refs.select.fieldModel) {
          return "Выберите один из предложенных вариантов:";
        } else {
          return "Начните писать и выберите один из предложенных вариантов";
        }
      }

      return "";
    },
    addressModel: {
      get() {
        return {
          value: this.model,
        };
      },
      set(v) {
        this.$emit("change", v.value);
      },
    },
  },
  watch: {
    model() {
      this.list = [];
    },
  },
  mounted() {
    this.isMounted = true;
  },
  methods: {
    clearList() {
      this.list = [];
    },
    focus(v) {
      if (
        this.addressModel ||
				(!this.addressModel && this.list && this.list.length > 0)
      )
        return;

      this.getList(v);
    },
    getListTimeout(v) {
      this.$emit("change", v);
      this.warning = "";
      this.list = [];
      this.showLoading = 5;
      clearTimeout(this.timeoutID);
      this.timeoutID = setTimeout(() => this.getList(v), 600);
    },
    getList(v) {
      if (!v) {
        this.list = [];
        this.showLoading = 0;
        return;
      }

      this.$infra.dadata
        .request("address", {
          count: 5,
          restrict_value: true,
          query: v,
        })
        .finally(() => {
          this.showLoading = 0;
        })
        .then((response) => {
          if (((response || {}).suggestions || {}).length > 0) {
            this.list = response.suggestions;
          } else {
            this.list = [];
          }

          if (this.list.length === 0)
            this.warning = "Нет вариантов удовлетворяющих запросу";
        });
    },
  },
};
</script>
