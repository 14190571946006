export default {
  on: function () {
    document
      .querySelector(".loader-wrapper")
      .setAttribute("style", "display:block");
    document.body.setAttribute(
      "style",
      "overflow:hidden;pointer-events:none;user-select:none;"
    );
  },
  off() {
    document
      .querySelector(".loader-wrapper")
      .setAttribute("style", "display:none");
    document.body.removeAttribute("style");
  },
};
